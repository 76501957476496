
export const ApiCall = async (name, phone, subject) => {
    console.log(name, phone, subject);
    if (name === '' || phone === '' || subject === '') {
        throw new Error('Preencha todos os campos');
    }

    try {

        const message = {
            nome: name,
            telefone: phone,
            assunto: subject
        }

        const response = await fetch('https://smsfact.vercel.app/api/email', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization'
            },
            body: JSON.stringify(message)
        });
        const data = await response.json();
        console.log(data);
        return data
    } catch (error) {
        return 'error'

    }

}