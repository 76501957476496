import React from "react";
import { SecSubTitle, SecTitle, Button } from "../../components";

const CtaOne = ({ className }) => (
  <div
    className={`z-index-common background-image ${className || ""}`}
    style={{ backgroundImage: "url(images/bg/cta-bg-1-1.jpg" }}
  >
    <div className="container">
      <div className="row text-center text-lg-start align-items-center justify-content-between">
        <div className="col-lg-auto">
          <SecSubTitle className="text-white">
            Serviços especializados em preparação de impostos, obtenção de ITIN,
            abertura de empresas e mais.
          </SecSubTitle>
          <SecTitle className="cta-title1 h1">
            Simplificando Seus Impostos nos EUA
          </SecTitle>
          <br />
        </div>
        <div className="col-lg-auto">
          <Button>
            Agende uma Consulta
            <i className="far fa-arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default CtaOne;
