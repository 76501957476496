import React from "react";
import Slider from "react-slick";
import { Button } from "../../components";

const HeroOne = () => {
  const HEROCONTENT = [
    {
      image: "images/hero/hero-1-1.jpg",
      title: (
        <>
          Tax 2025 <span className="hero-innertext">Tax Season 2025</span>{" "}
          <br /> com Segurança e Tranquilidade
        </>
      ),
      text: "Maximize seus benefícios fiscais e evite problemas com o IRS. Deixe que nossos especialistas cuidem de tudo para você.",
      btn1: {
        text: "Comece Agora",
        path: "/agenda",
      },
      btn2: {
        text: "Converse com Especialista",
        path: "/agenda",
      },
    },
    {
      image: "images/hero/hero-1-2.jpg",
      title: (
        <>
          Agende Sua Consulta com{" "}
          <span className="hero-innertext">Tax 2025</span> <br /> Apenas um
          Clique
        </>
      ),
      text: "Seu tempo é valioso! Garanta já um horário com nossos especialistas e tenha toda a assistência necessária.",
      btn1: {
        text: "Comece Agora",
        path: "/agenda",
      },
      btn2: {
        text: "Converse com Especialista",
        path: "/agenda",
      },
    },
    {
      image: "images/hero/hero-1-3.jpg",
      title: (
        <>
          Imposto de Renda:{" "}
          <span className="hero-innertext">Primeiro Passo</span> <br /> Para Sua
          Casa Própria
        </>
      ),
      text: "Ter seus impostos em dia é essencial para conseguir o financiamento ideal para o seu imóvel nos EUA. Vamos ajudá-lo nesse caminho.",
      btn1: {
        text: "Comece Agora",
        path: "/agenda",
      },
      btn2: {
        text: "Converse com Especialista",
        path: "/agenda",
      },
    },
  ];

  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider className="hero-layout1" {...settings}>
      {HEROCONTENT.map((slide, index) => (
        <div key={index}>
          <div className="hero-inner">
            <div className="hero-shape1"></div>
            <div className="hero-shape2"></div>
            <div
              className="hero-bg background-image"
              style={{ backgroundImage: `url(${slide.image})` }}
            ></div>
            <div className="container">
              <div className="hero-content">
                <h1 className="hero-title">{slide.title}</h1>
                <p className="hero-text">{slide.text}</p>
                <div className="hero-btns">
                  <Button path={slide.btn1.path}>
                    {slide.btn1.text}
                    <i className="far fa-arrow-right" />
                  </Button>
                  <Button path={slide.btn2.path} className="style2">
                    {slide.btn2.text}
                    <i className="far fa-arrow-right" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default HeroOne;
