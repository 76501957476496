import React from "react";
import ReactGA from 'react-ga4';
import '../assets/css/obrigado.css';
// import { useNavigate } from "react-router-dom";


ReactGA.initialize([
  {
    trackingId: "G-3DJ763GDWX",
    gaOptions: {
      name: 'Obrigado',
      // userId: XXX
    },
  },

])

ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });


const Obrigado = () => {

  return (
    <div className="obrigado-agenda">
      <div className="obrigadoBox">
        <img src="/images/logo.png"
          alt="Smart Tax Returns"
          style={{
            width: "30%",
            height: 'auto',
            marginTop: '15px'
          }} />
        <h1 style={{
          fontSize: '65px',
          textAlign: 'center',
          width: '100%',
          marginTop: '20px',
          marginBottom: '20px',
          lineHeight: '1.2',
          textTransform: 'uppercase'
        }}>Sucesso!</h1>
        <p style={{ width: "100%", textAlign: 'center' }}>Seu cadastro foi enviado! Aguarde nosso contato.</p>
        <button style={{
          width: '100%',
          height: '70px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
          marginBottom: '10px',
          marginTop: '50px',
          backgroundColor: '#49BF88',
          color: 'white',
          cursor: 'pointer'
        }} onClick={() => window.location.href = '/'}>Voltar</button>
      </div>
      <p style={{
        fontSize: '12px',
        marginTop: '20px',
        color: 'white',
      }}>Smart Tax Returns © 2023</p>
    </div>
  );
};

export default Obrigado;
