import React, { useState } from "react"; import ReactGA from 'react-ga4';
import { ApiCall } from "../utils/ApiCall";
import { useNavigate } from 'react-router-dom';
import '../assets/css/agenda.css'


ReactGA.initialize([
  {
    trackingId: "G-3DJ763GDWX",
    gaOptions: {
      name: 'Agenda',
      // userId: XXX
    },
  },

])

ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });


const Agenda = () => {
  const [useName, setName] = useState('')
  const [usePhone, setPhone] = useState('')
  // const [useSubject, setSubject] = useState('Entre em contato com Lead')
  const navigate = useNavigate()

  const handleChange = (e) => {
    let input = e.target.value;

    // Remove all non-numeric characters
    input = input.replace(/\D/g, "");

    // Limit to 9 digits
    if (input.length > 10) {
      input = input.slice(0, 10);
    }

    // Apply the (XXX) XXX-XXX format using regex
    input = input.replace(/(\d{3})(\d{3})?(\d{4})?/, (_, p1, p2, p3) => {
      if (p3) return `(${p1}) ${p2}-${p3}`;
      if (p2) return `(${p1}) ${p2}`;
      return `(${p1}`;
    });

    setPhone(input);
  };


  return (
    <div className="base-agenda" >
      <div className="agendaBox" >
        <img src="/images/logo.png"
          alt="Smart Tax Returns"
          style={{
            width: "30%",
            height: 'auto',
            marginTop: '15px'
          }} />
        <h1 style={{
          fontSize: '30px'
          , textAlign: 'center',
          width: '80%',
          marginTop: '20px',
          marginBottom: '20px',
          lineHeight: '1.2',
          textTransform: 'uppercase'
        }}>Preencha e nossa equipe entrará em contato!</h1>
        {/* <p>This is the About page of our website.</p> */}
        <p style={{
          margin: 0,
          width: '100%',
          color: "#021f59"
        }}>Name</p>
        <input type="text" style={{
          width: '100%',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
          marginBottom: '10px'
        }} onChange={(e) => setName(e.target.value)} />
        <p style={{
          margin: 0,
          width: '100%',
          color: "#021f59"
        }}>Contato (Telefone)</p>
        <input type="text" style={{
          width: '100%',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
          marginBottom: '10px'
        }} onChange={handleChange} value={usePhone} />
        {/* <p style={{
          margin: 0,
          width: '100%',
          color: "#021f59"
        }}>Tipo de Serviço</p>
        <select style={{
          width: '100%',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
          marginBottom: '10px',
          backgroundColor: 'white',
          color: "#021f59"
        }} onChange={(e) => setSubject(e.target.value)}>
          <option style={{
            color: "#021f59"
          }} value='' selected di>Selecione</option>
          <option style={{
            color: "#021f59"
          }} value="Pessoal">Imposto de Renda Pessoal</option>
          <option style={{
            color: "#021f59"
          }} value="Empresa">Imposto de Renda para sua Empresa</option>
          <option style={{
            color: "#021f59"
          }} value="Abertura de Empresa">Abertura de Empresa</option>
          <option style={{
            color: "#021f59"
          }} value="ITIN">ITIN Number</option>
          <option style={{
            color: "#021f59"
          }} value="Outros">Outros</option>
        </select> */}
        <button style={{
          width: '100%',
          height: '70px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
          marginBottom: '10px',
          marginTop: '10px',
          backgroundColor: '#021f59',
          color: 'white',
          cursor: 'pointer'
        }} onClick={() => ApiCall(useName, usePhone, 'Entre em contato com Lead').then(e => navigate('/obrigado')).catch(() => alert('Faltou algo... Preencha todos os campos do formulário!'))}>Enviar</button>
      </div>
    </div>
  );
};

export default Agenda;
